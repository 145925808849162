// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Following_section_1TGHx{display:grid;grid-template-rows:min-content 1fr;height:100%}.Following_nick_3XHmK{word-break:break-all}.Following_center_1KUG0{align-self:center}.Following_ml9_3VVQV{margin-left:9px}.Following_button_3kf8A{justify-self:flex-end}.Following_content_1Ix5n{display:grid;grid-template-columns:1fr auto;align-self:center}.Following_header_31b9b{background:#f8f8f8}.Following_title_2J16t{padding:7.5px;font-weight:600;font-size:15px;line-height:20px;text-align:center}.Following_search_3xvfH,.Following_title_2J16t{box-shadow:inset 0 -1px 0 #e5e3e3}.Following_search_3xvfH{padding:12px}.Following_search_3xvfH .Following_input_1_T_x input{color:var(--supster-secondary);background:#fff;border-color:#e5e3e3}.Following_search_3xvfH .Following_input_1_T_x svg{color:#e5e3e3!important}.Following_app_g2AhM{grid-template-columns:auto 120px;align-items:center;padding:12px;-moz-column-gap:12px;column-gap:12px;box-shadow:inset 0 -1px 0 #e5e3e3}.Following_app_g2AhM,.Following_app_g2AhM .Following_container_362S3{display:grid}.Following_app_g2AhM .Following_container_362S3 span{word-break:break-all}", ""]);
// Exports
exports.locals = {
	"section": "Following_section_1TGHx",
	"nick": "Following_nick_3XHmK",
	"center": "Following_center_1KUG0",
	"ml9": "Following_ml9_3VVQV",
	"button": "Following_button_3kf8A",
	"content": "Following_content_1Ix5n",
	"header": "Following_header_31b9b",
	"title": "Following_title_2J16t",
	"search": "Following_search_3xvfH",
	"input": "Following_input_1_T_x",
	"app": "Following_app_g2AhM",
	"container": "Following_container_362S3"
};
module.exports = exports;
