<template>
  <div :class="$style.section">
    <div :class="$style.header">
      <div :class="$style.title">{{ clientFollowCount }} {{ $t('follow') }}</div>
      <div :class="$style.search">
        <search-input @input="onInput" @close="onClose" :class="$style.input" />
      </div>
    </div>
    <v-grid :length="followingLength" @infinite="onGetFollowing">
      <div v-for="app in following" :key="app.id" :class="$style.app">
        <v-avatar
          :src="appAvatarSrc(app)"
          :srcset="appAvatarSrcset(app)"
          size="md-3"
          progress-color="primary"
          :to-app="app"
        >
          <template #rightContent>
            <span :class="[$style.nick, $style.ml9, $style.center]">{{ appName(app) }}</span>
          </template>
        </v-avatar>

        <v-button
          :class="[$style.button]"
          size="md"
          :color="app.my_app_subscription ? 'border-primary' : 'primary'"
          @click="onSetAppSubscription(app)"
          >{{ $t(`followAction.${app.my_app_subscription ? 'unfollow' : 'follow'}`) }}</v-button
        >
      </div>
      <template #placeholder>
        <p-chat-preview v-for="i in 30" :key="i" />
      </template>
    </v-grid>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import debounce from 'lodash/debounce'

import SearchInput from '@elements/inputs/list/SearchInput.vue'
import VGrid from '@layouts/VGrid.vue'
import VAvatar from '@layouts/avatar/VAvatar.vue'
import VButton from '@elements/VButton.vue'
import PChatPreview from '@placeholders/PChatPreview.vue'

export default Vue.extend({
  name: 'Following',
  components: { VButton, VAvatar, SearchInput, VGrid, PChatPreview },
  computed: {
    ...mapGetters('App', [
      'appId',
      'appAvatarSrc',
      'appAvatarSrcset',
      'appName',
      'appUserName',
      'isAppFollowed',
    ]),
    ...mapGetters('Client', ['clientFollowCount']),
    ...mapGetters('Follow', ['following', 'followingLength']),
  },
  methods: {
    ...mapActions('App', ['setAppSubscription']),
    ...mapActions('Me', ['getMe']),
    ...mapActions('Follow', ['getFollowing']),
    ...mapMutations('Follow', ['resetFollowing', 'resetSearchQuery', 'setFollowingQuery']),
    ...mapMutations('Feed', ['resetFeed']),
    async onGetFollowing(scroll) {
      await this.getFollowing({ scroll })
    },
    async onSetAppSubscription(app) {
      await this.setAppSubscription({
        id: app.my_app_subscription ? app.my_app_subscription.id : app.id,
        isFollowed: app.my_app_subscription,
      })
      this.resetFeed()
    },
    onInput(value) {
      this.setFollowingQuery(value)
      this.onSearch()
    },

    // eslint-disable-next-line func-names
    onSearch: debounce(async function () {
      this.resetFollowing()
      await this.getFollowing({})
    }, 500),
    onClose() {
      this.setSearchQuery('')
      this.onSearch()
    },
  },

  async beforeDestroy() {
    await this.getMe({})
    this.resetFollowing()
    this.resetSearchQuery()
  },
})
</script>

<style lang="scss" module>
.section {
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100%;
}

.nick {
  word-break: break-all;
}

.center {
  align-self: center;
}

.ml9 {
  margin-left: 9px;
}

.button {
  justify-self: flex-end;
}

.content {
  display: grid;
  grid-template-columns: 1fr auto;
  align-self: center;
}

.header {
  background: $shadow;
}

.title {
  padding: 7.5px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  box-shadow: inset 0px -1px 0px $secondary-light;
}

.search {
  padding: 12px;
  box-shadow: inset 0px -1px 0px $secondary-light;

  & .input input {
    color: $secondary;
    background: white;
    border-color: $secondary-light;
  }

  & .input svg {
    color: $secondary-light !important;
  }
}

.app {
  display: grid;
  grid-template-columns: auto 120px;
  align-items: center;
  padding: 12px;
  column-gap: 12px;
  box-shadow: inset 0px -1px 0px $secondary-light;

  & .container {
    display: grid;
    span {
      word-break: break-all;
    }
  }
}
</style>
